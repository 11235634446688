import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getUrlS3 } from "services/funcHelper";
import {
  convertURL,
  convertUploadPayload,
  getTermsIds,
  insertCustomizedFont,
  replaceWidgetURL,
} from "services/widgetBuilder";
import {
  DEFAULT_PAYLOAD,
  DURATION,
  FAILED,
  IN_CAPTURATE,
  IN_PROGRESS,
  LIMITATION_ERROR_TYPE,
  MAX_PERCENT,
  NONE,
  ORG_TYPE,
  PENDO_TRACKING_TYPE,
  SCRIPT_TAG,
  SUCCEEDED,
  UIW_ADMISSIONS,
  UPLOADER_TYPE,
} from "../../../constants/common";
import configUrls from "../../../constants/configUrls";
import THEME from "../../../constants/themes";
import WidgetBuilderContext from "../../../contexts/WidgetBuilder";
import utils from "../../../services/utils";
import {
  cancelRequest,
  getClientDetailEnd,
  getClientDetailRequest,
  sendEmailRequest,
  setUploadProgress,
  storeData,
  storeGoogleDataRequest,
  uploadContentRequest,
} from "../../../stores/actions";
import {
  StyledDetailWidget,
  StyledWrapperDetailWidget,
} from "../../../styled-components/WidgetBuilder/WidgetBuilderStyled";
import NotFound from "../../pages/NotFound/NotFound";
import useScrollHandler from "../../shared/ScrollHandler";
import Default from "../Default/Default";
import CancelUpload from "../UploadProgress/CancelUpload";
import UploadProgress from "../UploadProgress/UploadProgress";
import WidgetInstagram from "./Instagram/WidgetInstagram";
import WidgetTikTok from "./TikTok/WidgetTikTok";
import UploadLimitTerms from "./UploadLimitTerms";
import UploaderConfirmation from "./UploaderConfirmation";
import WidgetBody from "./WidgetBody";
import WidgetFooter from "./WidgetFooter";
import WidgetHeader from "./WidgetHeader";
import WidgetStatus from "./WidgetStatus";
import WidgetYouTube from "./YouTube/WidgetYouTube";
import SkeletonFrame from "./SkeletonFrame";

const WidgetBuilder = (props) => {
  const wrapperRef = useRef();
  const isMobile = utils.useDevice();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { globalProps } = props;
  const { requestAPI, isShowLoading, setIsShowLoading } = globalProps;
  const {
    fileProgress,
    uploadedContentList,
    createContentStatus,
    createdContent,
    clientDetail,
    getClientDetailStatus,
    isCancelledProgressModal = false,
    uploadSocialContentResult,
    uploadSocialContentStatus,
    uploadSocialContentError,
    storedCode,
    uploadSourceType,
  } = useSelector((state) => state.widgets);
  const { error: googleApiError } = useSelector((state) => state.googleStore);

  const defaultGoogleDriveData = {
    selectedMediaList: [],
    accessToken: "",
  };

  const [isMoreField, setIsMoreField] = useState(false);
  const [isFailed, setIsFailed] = useState();
  const [formState, setFormState] = useState(1); // 1 Upload Page, 2 Thank You Page
  const [widgetInfo, setWidgetInfo] = useState();
  const [uploadPayloadList, setUploadPayloadList] = useState([]);
  const [uploadResults, setUploadResults] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const [payload, setPayload] = useState({ ...DEFAULT_PAYLOAD });
  const [videoList, setVideoList] = useState(null);
  const [selectedVideoList, setSelectedVideoList] = useState([]);
  const [tikTokUser, setTikTokUser] = useState(null);
  const [activeUploaderType, setActiveUploaderType] = useState(null);

  const [isCancelUpload, setIsCancelUpload] = useState(false);
  const [isUploadLimitTerms, setIsUploadLimitTerms] = useState(false);
  const [googleDrive, setGoogleDrive] = useState(defaultGoogleDriveData);
  const [modalType, setModalType] = useState();
  const [terms, setTerms] = useState([]);
  const [authorizeWindow, setAuthorizeWindow] = useState(() => {});

  const pathLocation = useLocation();
  const { search } = pathLocation;
  const clientId = utils.getClientId(search);
  const code = utils.getSearchValue(search, "code");
  const campaignId = utils.getChallengeId(search);
  const href = convertURL(pathLocation).toLowerCase();

  const windowHref = window.location.href;

  useScrollHandler(wrapperRef);

  useEffect(() => {
    const fullHref = window.location.href;
    if (fullHref.includes(UIW_ADMISSIONS.DEFAULT_HREF)) {
      history.push(UIW_ADMISSIONS.REDIRECT_HREF);
      return;
    }
    handlePostMessage();
    if ((code && !clientDetail) || !code) {
      getClientDetail(href);
    }
  }, []);

  useEffect(() => {
    if (authorizeWindow && storedCode) {
      authorizeWindow.close();
    }
  }, [authorizeWindow, storedCode]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event?.data?.passedParameter) {
        const { passedParameter } = event.data;
        const uploadSource = passedParameter.includes("scopes=user.info.basic")
          ? UPLOADER_TYPE.TIKTOK
          : UPLOADER_TYPE.INSTAGRAM;
        if (authorizeWindow) {
          authorizeWindow.close();
        }
        const href = utils.removeQueryStringParameter(
          windowHref,
          uploadSource === UPLOADER_TYPE.INSTAGRAM
            ? ["code", "scopes", "state"]
            : ["code"]
        );
        const updatedUrl = replaceWidgetURL(href, passedParameter);
        const params = new URLSearchParams(new URL(updatedUrl).search);
        if (!storedCode) {
          dispatch(
            storeData({
              storedCode: utils.getSearchValue(params, "code"),
              uploadSourceType: updatedUrl.includes("scopes=user.info.basic")
                ? UPLOADER_TYPE.TIKTOK
                : UPLOADER_TYPE.INSTAGRAM,
            })
          );
        }
        return;
      }
    });
  }, []);

  const socialSource = useMemo(() => {
    return {
      isTikTok: uploadSourceType === UPLOADER_TYPE.TIKTOK,
      isInstagram: uploadSourceType === UPLOADER_TYPE.INSTAGRAM,
    };
  }, [uploadSourceType]);

  const handlePostMessage = () => {
    if (window && window.opener) {
      window.opener?.postMessage(
        {
          passedParameter: search.substring(1),
        },
        "*"
      );
    }
  };

  const isWidget = useMemo(() => {
    return widgetInfo && !isFailed && !isShowLoading;
  }, [widgetInfo, isShowLoading, isFailed]);

  useEffect(() => {
    if (widgetInfo) {
      const { metadata } = widgetInfo;
      const { colors } = metadata || {};

      if (colors?.enableCustomFontFamily) {
        insertCustomizedFont(colors?.fileFontUrls);
      }
    }
  }, [widgetInfo, widgetInfo?.metadata?.colors?.fileFontUrls, code]);

  useEffect(() => {
    if (createContentStatus === SUCCEEDED && createdContent) {
      setIsShowLoading(false);
      uploadContent(createdContent);
    }
  }, [createContentStatus]);

  useEffect(() => {
    if (clientDetail && widgetInfo) {
      handlePendoTracking(clientDetail, widgetInfo);
      dispatch(
        storeData({
          isHiddenGeneralLoading: false,
        })
      );
    }
  }, [clientDetail, widgetInfo]);

  useEffect(() => {
    if (uploadSocialContentStatus === SUCCEEDED) {
      handleFormState([]);
      if (activeUploaderType === UPLOADER_TYPE.GOOGLE_DRIVE) {
        setGoogleDrive(defaultGoogleDriveData);
      }
    }

    if (uploadSocialContentStatus === FAILED && uploadSocialContentError) {
      setIsShowLoading(false);
      handleErrorMsg(uploadSocialContentError);
    }

    if (
      uploadSocialContentStatus === FAILED ||
      uploadSocialContentStatus === SUCCEEDED
    ) {
      setIsShowLoading(false);
      dispatch(
        storeData({
          uploadSocialContentStatus: NONE,
          uploadSocialContentError: null,
        })
      );
    }
  }, [uploadSocialContentStatus, uploadSocialContentError]);

  useEffect(() => {
    if (googleApiError) {
      handleErrorMsg(googleApiError);
    }
  }, [googleApiError]);

  const isInProgress = useMemo(() => {
    return fileProgress.some(
      (item) => item.status === IN_PROGRESS || item.status === IN_CAPTURATE
    );
  }, [fileProgress, isCancelledProgressModal]);

  const isInError = useMemo(() => {
    return fileProgress.every((item) => !_.isEmpty(item?.limitationError));
  }, [fileProgress, isCancelledProgressModal]);

  const isCompleteUpload = useMemo(() => {
    const fileInSucceed = fileProgress.filter(
      (item) => item.status === SUCCEEDED
    );

    return (
      !!fileProgress.length &&
      !!uploadedContentList.length &&
      !isInProgress &&
      uploadedContentList.length === fileInSucceed.length
    );
  }, [isInProgress, fileProgress, uploadedContentList]);

  useEffect(() => {
    if (isCompleteUpload) {
      if (uploadedContentList.length) {
        handleSendEmail();
      }
      setIsCancelUpload(false);
    }
  }, [isCompleteUpload, uploadedContentList]);

  useEffect(() => {
    if (isCompleteUpload) {
      // setTimeout is used to user checking error
      setTimeout(
        () => {
          if (uploadedContentList.length && !isCancelledProgressModal) {
            resetDataInStore();
            handleFormState(uploadedContentList);
          }
        },
        fileProgress.some((item) => item.limitationError)
          ? DURATION.S_3
          : DURATION.S_0
      );
    }
  }, [isCompleteUpload, isCancelledProgressModal]);

  useEffect(() => {
    if (getClientDetailStatus === SUCCEEDED) {
      if (clientDetail) {
        getMetaDataWidget(href, clientDetail.id);
        dispatch(getClientDetailEnd());
      } else {
        setIsFailed(true);
      }
    }
  }, [clientDetail, getClientDetailStatus]);

  const handleSendEmail = () => {
    const payloadSendEmail = {
      email: payload?.emailAddress,
      requestIds: [],
      termsIds: getTermsIds(terms),
      widgetUrl: window.location.href,
    };
    if (uploadedContentList.length) {
      payloadSendEmail.requestIds = uploadedContentList.map(
        (item) => item.requestId
      );
    }
    if (campaignId) {
      payloadSendEmail.campaignId = campaignId;
    }
    dispatch(sendEmailRequest(payloadSendEmail));
  };

  const handlePendoTracking = (clientDetail, widgetInfo) => {
    const { id: clientId, orgType } = clientDetail;
    const dataTracking = {
      clientId,
      widgetId: widgetInfo.id,
    };

    utils.pendoTracking({
      ...dataTracking,
      trackType: PENDO_TRACKING_TYPE.VISITED_ID,
    });

    utils.pendoTracking({
      ...dataTracking,
      trackType: PENDO_TRACKING_TYPE.VISITED,
    });

    if (orgType === ORG_TYPE.CLIENT) {
      utils.pendoTracking({
        ...dataTracking,
        trackType: PENDO_TRACKING_TYPE.ALL_VISITED,
      });
    }
  };

  const onUploadProgress = async (params) => {
    const { progress, data, fileProgressId, requestId } = params;
    const { loaded, total } = progress;
    const percentageProgress = Math.floor((loaded / total) * MAX_PERCENT);
    const dataObject = utils.convertFormDataToObject(data);
    const endTime = new Date().getTime();
    const duration = (endTime - dataObject.startTime) / 1000;
    const bps = loaded / duration;
    const time = (total - loaded) / bps;

    const payload = {
      ...dataObject,
      fileProgressId,
      progress: percentageProgress,
      remainingTime: time,
      requestId,
    };

    dispatch(setUploadProgress(payload));
  };

  const handleError = async (file) => {
    return await utils.validateFileLimitation({
      file: file,
      widgetInfo,
      t,
    });
  };

  const uploadContent = (createdContent) => {
    const contentList = convertUploadPayload(
      createdContent,
      widgetInfo,
      campaignId,
      uploadPayloadList
    );
    if (contentList.length)
      contentList.forEach((content) => {
        const dataObject = utils.convertFormDataToObject(content.formData);
        const errorPromise = handleError(dataObject.file);
        errorPromise.then((result) => {
          if (!_.isEmpty(result)) {
            const { progress = 1, fileProgressId, searchParams } = content;
            const payload = {
              ...dataObject,
              fileProgressId,
              progress,
              remainingTime: 0,
              requestId: searchParams?.requestId,
              limitationError: result,
              status: FAILED,
            };
            dispatch(setUploadProgress(payload));
          } else {
            dispatch(
              uploadContentRequest({
                ...content,
                onUploadProgress,
                widgetInfo,
                isNewFlow: widgetInfo?.widgetSettingsDTO?.isNewFlow,
              })
            );
          }
        });
      });
  };

  const getMetaDataWidget = (widgetLink, clientId) => {
    const url = `${configUrls.API.widgetBuilder}?widgetUrl=${widgetLink}&clientId=${clientId}`;

    const successCallback = (resp) => {
      if (resp) {
        resp = { ...resp, metadata: JSON.parse(resp.metadata) };
        setWidgetInfo(resp);
      }
      setIsFailed(false);
    };
    const failedCallback = () => {
      setIsFailed(true);
    };
    const errorCallback = () => {};

    requestAPI("get", url, {}, successCallback, failedCallback, errorCallback);
  };

  const getClientDetail = (uploaderLink) => {
    dispatch(getClientDetailRequest({ uploaderLink }));
  };

  const handleFormState = (result) => {
    setUploadResults(result);
    setFormState(2);
    dispatch(
      storeData({
        isCancelledProgressModal: true,
      })
    );
  };

  const handleUploadMore = () => {
    setFormState(1);
    setActiveUploaderType("");
    resetData();
    resetDataInStore();
  };

  const className = search.includes(SCRIPT_TAG) ? SCRIPT_TAG : "";

  const getUploadResults = () => {
    if (activeUploaderType === UPLOADER_TYPE.YOUTUBE) {
      return uploadSocialContentResult;
    }

    return uploadedContentList.length ? uploadedContentList : uploadResults;
  };

  const renderWidget = () => {
    let { metadata = "" } = widgetInfo;

    if (metadata) {
      const {
        bgUrl = "",
        bgColor = "",
        formBackground = THEME.colors.white,
        highlight,
      } = metadata.colors;
      const { srcLogo = "", sizeLogo = {} } = metadata.header;

      if (formState === 1) {
        return (
          <StyledWrapperDetailWidget
            tabIndex={-1}
            ref={wrapperRef}
            bgUrl={getUrlS3(bgUrl)}
            bgColor={bgColor}
            formBackground={formBackground}
            className={className}
          >
            <StyledDetailWidget bgColor={formBackground} isMobile={isMobile}>
              <WidgetHeader sizeLogo={sizeLogo} srcLogo={getUrlS3(srcLogo)} />
              <WidgetBody
                detailWidget={widgetInfo}
                isMoreField={isMoreField}
                setIsMoreField={setIsMoreField}
                globalProps={globalProps}
                handleFormState={handleFormState}
              />
              <WidgetFooter
                highlight={highlight}
                handleUploadLimitTerms={handleUploadLimitTerms}
                formState={formState}
              />
            </StyledDetailWidget>
          </StyledWrapperDetailWidget>
        );
      }

      return (
        <UploaderConfirmation
          isMobile={isMobile}
          uploadResults={getUploadResults()}
          metaData={metadata}
          handleUploadMore={handleUploadMore}
          activeUploaderType={activeUploaderType}
          className={className}
        />
      );
    }
  };

  const handleUploadLimitTerms = () => {
    setIsUploadLimitTerms(!isUploadLimitTerms);
  };

  const renderDefaultWidget = () => {
    if (clientId) {
      return <Default globalProps={globalProps} />;
    }

    return <NotFound globalProps={globalProps} />;
  };

  const resetData = () => {
    setSelectedVideoList([]);
    setGoogleDrive({ selectedMediaList: [], accessToken: "" });
  };

  const resetDataInStore = () => {
    const resetValues = {
      createdContent: null,
      createContentStatus: NONE,
      isCancelledProgressModal: true,
      uploadSocialContentResult: [],
      fileProgress: [],
    };

    dispatch(storeData(resetValues));
  };

  const confirmCancel = () => {
    if (fileProgress.length) {
      fileProgress.forEach((item) => {
        if (item.status === IN_PROGRESS)
          dispatch(
            cancelRequest({
              fileProgressId: item.fileProgressId,
              isNotRemove: true,
              actionType: LIMITATION_ERROR_TYPE.CANCEL,
            })
          );
      });
    }
    resetData();
    resetDataInStore();
    setIsCancelUpload(false);
  };

  const onCancelUpload = () => {
    if (isInProgress && !isInError) {
      setIsCancelUpload(true);
    } else {
      resetDataInStore();
    }
  };

  const handleErrorMsg = (error) => {
    setErrorMsg(error);

    setTimeout(() => {
      setErrorMsg("");
      if (googleApiError) {
        dispatch(
          storeGoogleDataRequest({
            error: null,
          })
        );
      }
    }, DURATION.S_10);
  };

  const renderSocialComponents = () => {
    const { colors } = widgetInfo.metadata || {};
    const { enableSharing, enableSharingYouTube, enableSharingInstagram } =
      colors || {};

    return (
      <>
        {enableSharing && (
          <WidgetTikTok globalProps={globalProps} isMobile={isMobile} />
        )}
        {enableSharingYouTube && <WidgetYouTube isMobile={isMobile} />}
        {enableSharingInstagram && <WidgetInstagram isMobile={isMobile} />}
      </>
    );
  };

  const contextValues = {
    widgetInfo,
    videoList,
    setVideoList,
    selectedVideoList,
    setSelectedVideoList,
    tikTokUser,
    setTikTokUser,
    activeUploaderType,
    setActiveUploaderType,
    isCancelUpload,
    setIsCancelUpload,
    resetDataInStore,
    setUploadPayloadList,
    uploadInfo: payload,
    setUploadInfo: setPayload,
    isMobile,
    formState,
    className,
    googleDrive,
    setGoogleDrive,
    modalType,
    setModalType,
    setIsShowLoading,
    errorMsg,
    setErrorMsg,
    handleErrorMsg,
    terms,
    setTerms,
    socialSource,
    authorizeWindow,
    setAuthorizeWindow,
  };

  return (
    <WidgetBuilderContext.Provider value={contextValues}>
      {isFailed === undefined ? (
        <SkeletonFrame />
      ) : (
        <div>
          {isWidget && renderWidget()}
          {!isWidget && renderDefaultWidget()}
        </div>
      )}

      {!!fileProgress.length && !isCancelledProgressModal && (
        <UploadProgress
          onCancelUpload={onCancelUpload}
          widgetInfo={widgetInfo}
        />
      )}
      {widgetInfo && (
        <CancelUpload
          visible={isCancelUpload}
          onConfirm={confirmCancel}
          bgSubmit={widgetInfo.metadata.colors.bgSubmit}
          onCancel={() => {
            setIsCancelUpload(false);
          }}
        />
      )}

      {widgetInfo && isUploadLimitTerms && (
        <UploadLimitTerms
          widgetDetail={widgetInfo}
          onClose={handleUploadLimitTerms}
          isMobile={isMobile}
        />
      )}

      {widgetInfo && (
        <WidgetStatus widgetInfo={widgetInfo} isMobile={isMobile} />
      )}

      {widgetInfo && renderSocialComponents()}
    </WidgetBuilderContext.Provider>
  );
};

WidgetBuilder.propTypes = {
  requestAPI: PropTypes.func,
  globalProps: PropTypes.object,
  isShowLoading: PropTypes.bool,
};

export default WidgetBuilder;
