import { HomeUrl } from "config";
import {
  CUSTOM_PROPERTY_TYPE,
  DEFAULT_FIELD_NAME,
  FIELD_NAME,
  MEDIA_TYPE,
  PLACEHOLDER_IN_BASE64,
  REGEX,
  REUSED_FIELD_NAME,
  SELECTION_TYPE,
  SELECTORS,
  SOCIAL_USERNAME,
  STORAGE_NAME,
  TYPE_CUSTOM_FIELD,
} from "constants/common";
import { EMAIL_OPT_IN } from "constants/widgetBuilder";
import { getUrlS3, initCustomizedFont } from "./funcHelper";
import utils from "./utils";

const setPayloadData = (listFields, payload) => {
  const basicFields = { ...payload.basicFields };
  const additionalFields = payload.additionalFields || [];

  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    const originalKey = key;
    key = key.toLowerCase();

    switch (true) {
      case key.includes(DEFAULT_FIELD_NAME.email.toLowerCase()):
        basicFields[FIELD_NAME.email] = value;
        break;

      case key.includes(DEFAULT_FIELD_NAME.firstName.toLowerCase()):
        basicFields[FIELD_NAME.firstName] = value;
        break;

      case key.includes(DEFAULT_FIELD_NAME.lastName.toLowerCase()):
        basicFields[FIELD_NAME.lastName] = value;
        break;

      case Object.values(REUSED_FIELD_NAME).includes(originalKey): {
        basicFields[originalKey] = value;
        break;
      }

      default: {
        if (!key.includes("fileupload") && !key.includes("fileuploads")) {
          listFields.forEach((field) => {
            const {
              fieldName,
              type,
              name: placeholder = "",
              label: name = "",
              answerType = "",
            } = field;

            let additionalItem = {
              ...field,
              placeholder,
              name,
              values: value,
              isTaggable: false,
              isSearchable: true,
            };

            if (key === fieldName.toLowerCase()) {
              if (type === TYPE_CUSTOM_FIELD.dropdown) {
                additionalItem = {
                  ...additionalItem,
                  values:
                    answerType === SELECTION_TYPE.MULTIPLE ? value : [value],
                  isTaggable: true,
                };
              }

              additionalFields.push(additionalItem);
            }
          });
        }
        break;
      }
    }
  });

  return {
    additionalFields,
    basicFields,
  };
};

const formatCustomProperties = (data) => {
  const customProperties = {
    creatorCustomProperties: [],
    contentCustomProperties: [],
  };
  let description = "";

  if (data && data.length) {
    data.forEach((item) => {
      if (item.mapping === CUSTOM_PROPERTY_TYPE.CONTENT || !item.mapping) {
        if (item.isContentDescription) {
          description = item?.values;
        } else {
          customProperties.contentCustomProperties.push(item);
        }
      } else {
        customProperties.creatorCustomProperties.push(item);
      }
    });
  }

  return { ...customProperties, description };
};

const creatorOptInEmailValues = (emailOptIn, terms) => {
  const { optInMethod = "", method = "" } = emailOptIn || {};
  const emailOptInItem = terms.find(
    (item) => item.fieldName === EMAIL_OPT_IN.FIELD_NAME
  );

  let creatorOptInEmail = {
    optInMethod: optInMethod || method,
    isOptIn: true,
  };

  if (emailOptInItem) {
    creatorOptInEmail.isOptIn = emailOptInItem.checked;
  }

  return { ...creatorOptInEmail };
};

const getSocialUsernameFromURL = (search) => {
  const instagramUsername = utils.getSearchValue(
    search,
    SOCIAL_USERNAME.INSTAGRAM
  );
  const twitterUsername = utils.getSearchValue(search, SOCIAL_USERNAME.TWITTER);
  const tiktokUsername = utils.getSearchValue(search, SOCIAL_USERNAME.TIKTOK);
  const youtubeUsername = utils.getSearchValue(search, SOCIAL_USERNAME.YOUTUBE);

  return {
    instagramUsername,
    twitterUsername,
    tiktokUsername,
    youtubeUsername,
  };
};

const handleSetAttributePlaceholder = (data, t) => {
  data.map((item) => {
    if (item.type === TYPE_CUSTOM_FIELD.dropdown) {
      const element = document.getElementById(item.fieldName);
      if (element) {
        element.setAttribute("placeholder", item.name);
        element.setAttribute(
          "alt",
          `${t("widget_builder.placeholder.dropdown")}: ${item.name}`
        );
      }
    }
  });
};

const setFieldRules = (item, t) => {
  let rules = [];

  if (item.required) {
    rules.push({
      required: true,
      message: t("validation.required", {
        field: item?.errorName || item.name,
      }),
    });
  }

  if (item.type === "email") {
    rules.push({
      type: "email",
      message: t("validation.invalid", { field: "Email" }),
    });
  }

  if (item.type === "phone") {
    rules.push({
      pattern: REGEX.PHONE_NUMBER,
      message: t("validation.invalid", { field: "Phone number" }),
    });
  }

  if (item.type === DEFAULT_FIELD_NAME.instagram) {
    rules.push({
      pattern: REGEX.INSTAGRAM_USERNAME,
      message: t("validation.invalid", { field: item?.errorName || item.name }),
    });
  }

  if (item.type === DEFAULT_FIELD_NAME.tiktok) {
    rules.push({
      pattern: REGEX.TIKTOK_USERNAME,
      message: t("validation.invalid", { field: item?.errorName || item.name }),
    });
  }

  if (item.type === DEFAULT_FIELD_NAME.twitter) {
    rules.push({
      pattern: REGEX.TWITTER_USERNAME,
      message: t("validation.invalid", { field: item?.errorName || item.name }),
    });
  }

  return rules;
};

const convertURL = (pathLocation) => {
  const { search, pathname } = pathLocation;
  const clientId = utils.getClientId(search);

  if (pathname && pathname !== "/") {
    return `${HomeUrl}${pathname}`;
  }

  return `${HomeUrl}/?cl=${clientId}`;
};

const thumbnailGenerator = async (file) => {
  let imageUrl = "";
  let isError = false;
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  // Trigger video load
  await new Promise((resolve) => {
    video.preload = "metadata";
    video.addEventListener("loadeddata", () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to X
      video.currentTime = video.duration * 0.001;
    });
    video.addEventListener("seeked", () => resolve());
    video.addEventListener("error", () => {
      isError = true;
      imageUrl = PLACEHOLDER_IN_BASE64.VIDEO;
      return resolve();
    });

    // supports to work on the mobile browser (IOS)
    video.autoplay = true;
    video.muted = true;
    video.controls = false;
    video.setAttribute("webkit-playsinline", "webkit-playsinline");
    video.setAttribute("playsinline", "playsinline");
    video.src = file;
  });

  if (isError) {
    return imageUrl;
  }

  // Draw the thumbnail
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  imageUrl = canvas.toDataURL("image/png");
  return imageUrl;
};

const fetchGoogleDriveFile = ({ id, accessToken }) => {
  const url = "https://www.googleapis.com/drive/v3/files/" + id + "?alt=media";

  const options = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  fetch(url, options)
    .then((res) => res.blob())
    .then((blob) => {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      return imageUrl;
    });
};

const generateThumbnailList = (uploadedFiles) => {
  return uploadedFiles.map(async (item) => {
    const urlCreator = window.URL || window.webkitURL;
    const objectURL = urlCreator.createObjectURL(item);
    return item?.type?.includes(MEDIA_TYPE.VIDEO)
      ? await thumbnailGenerator(objectURL)
      : objectURL;
  });
};

const getIPStack = (location) => {
  const {
    continent_code = "",
    continent_name = "",
    country_code = "",
    country_name = "",
    region_code = "",
    region_name = "",
    city = "",
    zip = "",
    latitude = "",
    longitude = "",
  } = location || {};

  return {
    continentCode: continent_code,
    continentName: continent_name,
    countryCode: country_code,
    countryName: country_name,
    regionCode: region_code,
    regionName: region_name,
    city,
    zip,
    latitude,
    longitude,
  };
};

const getTermsIds = (terms) => {
  let termsIds = [];
  if (terms) {
    Object.keys(terms).forEach((key) => {
      if (terms[key].checked && terms[key].id) termsIds.push(terms[key].id);
    });
  }
  return termsIds;
};

const convertUploadPayload = (
  createdContent,
  widgetInfo,
  campaignId,
  uploadPayloadList
) => {
  const { id, selectId } = createdContent;
  let searchParams = {
    contentId: id,
    selectId,
    widgetId: widgetInfo.id,
    campaignId: campaignId,
  };
  let uploadPayloadListTemp = [...uploadPayloadList];

  uploadPayloadListTemp = uploadPayloadListTemp.map((item, index) => {
    searchParams = {
      ...searchParams,
      requestId: `${utils.randomId()}${index}`,
      isClone: index !== 0,
    };

    return {
      ...item,
      searchParams,
      fileProgressId: index + 1,
    };
  });
  return uploadPayloadListTemp;
};

const readFile = async (file) => {
  fetch(file)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const file = new File([blob], "gemini-no-media-thumbnail.png", {
        type: "image/png",
      });
      return file;
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
};

const convertUploadPayloadToFormData = async (
  data = {},
  uploadedFiles,
  uploadLocation
) => {
  const startTime = new Date().valueOf();

  const formDataList = await Promise.all(
    uploadedFiles.map(async (uploadedFile) => {
      const convertedData = {
        ...data,
        location: uploadLocation,
        file: uploadedFile,
        startTime,
      };
      if (uploadedFile?.type?.includes(MEDIA_TYPE.VIDEO)) {
        convertedData.videoDuration = await utils.getVideoDuration(
          uploadedFile
        );
      }
      const formData = utils.parseUploadFormData(convertedData);
      return { formData };
    })
  );

  return formDataList;
};

const replaceWidgetURL = (windowHref, additionalParams) => {
  const params = new URLSearchParams(new URL(windowHref).search);
  const hasAnyKey = params.keys().next().done === false;
  windowHref += `${hasAnyKey ? "&" : "?"}${additionalParams}`;
  return windowHref;
};

const setClientId = (clientDetail) => {
  const clientId = +sessionStorage.getItem(STORAGE_NAME.SESSION.CLIENT_ID);

  if (clientDetail && !clientId) {
    const { id, brandName } = clientDetail || {};
    sessionStorage.setItem(STORAGE_NAME.SESSION.CLIENT_ID, id);
    sessionStorage.setItem(STORAGE_NAME.SESSION.CLIENT_NAME, brandName);
  }
};

const checkedAllTerms = (terms) => {
  let isChecked;

  isChecked = terms.every((item) => {
    if (item.isTermsMandated) {
      if (item.checked) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });

  return isChecked;
};

const insertCustomizedFont = (fileFontUrls) => {
  const styleCustomizedFontEl = document.getElementById(
    SELECTORS.STYLE_CUSTOMIZED_FONT_ID
  );
  const head = document.getElementsByTagName("head")[0];

  if (styleCustomizedFontEl) {
    head.removeChild(styleCustomizedFontEl);
  }
  if (fileFontUrls && fileFontUrls.length) {
    initCustomizedFont(fileFontUrls.map((file) => getUrlS3(file)));
  }
};

const popupWindow = (authUrl) => {
  const w = window.innerWidth < 600 ? window.innerWidth : 800;
  const h = 850;
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  return window.open(
    authUrl,
    "authorizeWindow",
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${w},height=${h},top=${top},left=${left}`
  );
};

const getCodeFromURL = (url) => {
  const params = new URLSearchParams(new URL(url).search);
  return utils.getSearchValue(params, "code") || "";
};

export {
  checkedAllTerms,
  convertURL,
  convertUploadPayload,
  convertUploadPayloadToFormData,
  creatorOptInEmailValues,
  fetchGoogleDriveFile,
  formatCustomProperties,
  generateThumbnailList,
  getIPStack,
  getSocialUsernameFromURL,
  getTermsIds,
  handleSetAttributePlaceholder,
  insertCustomizedFont,
  popupWindow,
  replaceWidgetURL,
  setClientId,
  setFieldRules,
  setPayloadData,
  thumbnailGenerator,
  getCodeFromURL,
};
